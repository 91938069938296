<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        :min-width="280"
        :elevation="hover ? 15 : 2"
        :disabled="isLocked"
        link-focus-opacity="1"
        class="course-list-item transition-swing"
        :class="{ 'in-learning-path': isLearningPath }"
      >
        <v-card-title
          class="course-list-item__title"
          :style="backgroundImage"
          @click="view"
        >
          <div
            class="course-list-item__course-handler"
            v-allowed="[userTypes.ADMIN]"
            v-if="!isLearningPath"
          >
            <span v-handle class="handle course-handler" v-if="isAdmin">
              <v-icon class="" dark>mdi-dots-grid</v-icon>
            </span>
          </div>
          <div class="course-list-item__title__progress">
            <v-progress-circular
              :rotate="-90"
              :size="70"
              :width="4"
              :value="percent"
              color="primary"
            >
              <div class="course-list-item__title__progress__value">
                {{ percent }}%
              </div>
            </v-progress-circular>
          </div>
          <div class="course-list-item__title__wrapper">
            <div v-if="isAdmin" class="course-list-item__title__languages">
              <span
                v-for="(language, index) in languages"
                :key="index"
                class="course-list-item__title__languages__item"
                >{{ language }}</span
              >
            </div>
            <div>
              <h2 class="course-list-item__title__text">{{ title }}</h2>
              <f-rating-system
                :rating="{
                  size: 18,
                  isReadonly: true,
                  value: course.average_rating_course,
                }"
              />
            </div>
          </div>
        </v-card-title>
        <v-card-text class="course-list-item__content grow" @click="view">
          <div
            v-html="description"
            class="course-list-item__content__text"
          ></div>
          <br />
          <div
            v-if="
              course.trainingCategory.length != 0 &&
                $store.state.auth.user.type != 'student'
            "
          >
            <v-chip
              style="margin-right: 10px"
              v-for="category in course.trainingCategory"
              dark
              small
            >
              {{ category }}
            </v-chip>
          </div>
        </v-card-text>
        <div v-allowed="[userTypes.ADMIN]" class="admin-actions">
          <v-card-actions class="justify-space-between">
            <div>
              <course-status
                :status="course.status"
                @change="status => changeStatus(status)"
              />
            </div>
            <div class="btns">
              <a @click.stop="edit(course._id)">
                <v-icon>mdi-pencil-outline</v-icon>
              </a>
              <a @click.stop="remove(course._id)">
                <v-icon>mdi-delete-outline</v-icon>
              </a>
            </div>
          </v-card-actions>
        </div>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
import { HandleDirective } from 'vue-slicksort';
import strings from '../../helpers/strings';
import courseUtils from '../../helpers/course_utils';
import CourseStatus from '../Courses/CourseStatus.vue';
import RatingSystem from '../shared/Rating.vue';
import { getCourseProgress } from '../../services/progress';

export default {
  components: {
    CourseStatus,
    'f-rating-system': RatingSystem,
  },
  directives: {
    handle: HandleDirective,
  },
  props: ['course', 'isLocked', 'tab'],
  data: function() {
    return {
      progress: null,
      isLearningPath: false,
      currentTab: this.tab,
    };
  },
  computed: {
    title() {
      return this.course.title[this.currentLanguage];
    },
    description() {
      return strings.truncate(
        this.course.description[this.currentLanguage] || '',
        255
      );
    },
    languages() {
      return this.course.langs;
    },
    currentLanguage() {
      return this.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.languages[0];
    },
    percent() {
      if (this.progress) {
        const progress = getCourseProgress(this.course, this.course.structure);
        return progress;
      } else {
        return 0;
      }
    },
    backgroundImage() {
      if (this.course.image) {
        const image = courseUtils.imageURL(this.course.image);
        return `background-image: url(${image})`;
      }
    },
  },
  async created() {
    if (this.$route.name == 'learning-paths-view') {
      this.isLearningPath = true;
    }
    var userProgress = await this.$store.dispatch(
      'auth/getUserData',
      'progress'
    );
    if (
      userProgress &&
      userProgress.filter(item => item.courseID == this.course._id).length
    ) {
      this.progress = userProgress.filter(
        item => item.courseID == this.course._id
      )[0].modules;
    }
  },
  methods: {
    isAdmin() {
      return this.allowEdit;
    },
    allowEdit() {
      return (
        this.$store.state.auth.user.type == UserType.Admin ||
        this.$store.state.auth.user.type == UserType.TSM
      );
    },
    edit() {
      const id = this.course._id;
      this.$router.push({
        name: 'course-edit',
        params: { id },
      });

        this.$gtm.trackEvent({
          category: 'course-admin-actions',
          event: 'click',
          action: 'course-click',
          label: `edit-course-tab-${this.currentTab}`,
          value: `${id}`,
        });
    },
    view() {
      const id = this.course._id;

      this.$router.push({
        name: 'course-view',
        params: { id },
      });

      this.$gtm.trackEvent({
        category: 'course',
        event: 'click',
        action: 'course-click',
        label: 'view-course',
        value: `${id}`,
      });
    },
    async remove() {
      const id = this.course._id;
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.courses.remove.title'),
        content: this.$t('alerts.courses.remove.content'),
      });
      if (response) {
        await this.$store.dispatch('course/remove', id);
        await this.$store.dispatch('course/list', id);

        this.$gtm.trackEvent({
          category: 'course-admin-actions',
          event: 'click',
          action: 'course-click',
          label: `remove-course-tab-${this.currentTab}`,
          value: `${id}`,
        });
      }
    },
    async changeStatus(status) {
      this.course.status = status;

      await this.$store.dispatch('course/changeStatus', {
        _id: this.course._id,
        status: status,
        masters: this.course.masters,
      });

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'change-status',
        value: `${this.course._id}-${status}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/variables';

.clear-items {
  .course-list-item__course-handler {
    display: none;
  }
}

.course-list-item {
  .v-rating {
    padding-top: 10px;
  }
  height: 100%;
  &__title {
    background-color: $box-selected;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 230px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &::after {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: rgba(34, 32, 31, 0.3);
    }
    &__wrapper {
      position: relative;
      z-index: 2;
      padding: 20px 25px;
      background: rgba(12, 32, 51, 0.8);
      width: 100%;
      line-height: 1.2;
    }
    &__languages {
      display: flex;
      margin: 0 0 10px 0;
      &__item {
        color: #ffc007;
        margin: 0 5px 0 0;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
    &__text {
      font-size: 16px;
      color: white;
    }
    &__progress {
      position: absolute;
      z-index: 2;
      top: 18px;
      right: 16px;
      font-size: 14px;
      &__value {
        color: white;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }

  &__content {
    padding: 25px;
    cursor: pointer;
    &__text {
      font-size: 14px;
    }
  }
  &__course-handler {
    position: absolute;
    z-index: 2;
    top: 25px;
    left: 25px;
    font-size: 14px;
  }
}

.admin-actions {
  padding: 0 20px 15px 20px;
  hr {
    margin-bottom: 10px;
    border-color: #eeeeee;
  }
  a {
    opacity: 0.6;
    margin: 0 0 0 15px;
  }
  i.v-icon.v-icon {
    color: $default-color;
    &:hover {
      color: $primary;
    }
  }
  .btns {
    margin-top: -18px;
  }
}
</style>
