<template>
  <div>
    <v-progress-linear
      :style="`width: ${+width}px;`"
      class="report-progress"
      :class="{
        split: !hasSecondValue && !linear,
        zero: value == 0,
        linear: linear,
        finished: value == 100,
      }"
      :buffer-value="secondValue"
      :value="value"
      :stream="hasSecondValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    secondValue: {
      type: [Number, String],
      default: 0,
    },
    width: {
      type: [Number, String],
      default: 300,
    },
    linear: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasSecondValue() {
      return this.secondValue != 0;
    },
  },
};
</script>
