<template>
  <div style="padding: 10px; margin: -14px -14px 0 -14px">
    <div v-if="certificatesWithTitle.length">
      <span class="lms-title">
        Certificados de cursos
      </span>
      <div
        v-for="(item, index) in certificatesWithTitle"
        :key="index"
        class="cert_row"
        @click="downloadCertificate(item.courseID)"
      >
        <div>
          {{ item.viewTitle }}
        </div>
        <div>
          <v-icon>mdi-download</v-icon>
        </div>
      </div>
      <br />
    </div>
    <div v-if="customCertificatesList.length">
      <span class="lms-title">
        Outros certificados
      </span>
      <div
        v-for="item in customCertificatesList"
        :key="item.id"
        class="cert_row"
        @click="downloadCustomCertificate(item.certificateID)"
      >
        <div>
          {{ item.title }}
        </div>
        <div>
          <v-icon>mdi-download</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAll as getAllCertificates,
  download as downloadCustomCertificate,
} from '../../services/customCertificates';
import fileUtils from '../../helpers/file_utils';
import courseUtils from '../../helpers/course_utils';

export default {
  data: () => ({
    authCertificates: [],
    authCustomCertificates: [],
    certificatesWithTitle: [],
    customCertificates: [],
    list: [],
  }),
  computed: {
    customCertificatesList() {
      let certificate = {};
      let certificatesWithData = [];
      if (
        this.authCustomCertificates &&
        this.authCustomCertificates.length &&
        this.customCertificates &&
        this.customCertificates.length
      ) {
        const certificates = this.authCustomCertificates;
        certificates.forEach(cert => {
          if (cert) {
            certificate = this.customCertificates.find(
              item => item._id == cert.certificateID
            );
            try {
              certificatesWithData.push({
                title: certificate.data.title[this.$i18n.locale],
                date: cert.data.date,
                id: cert._id,
                certificateID: cert.certificateID,
              });
            } catch (e) {}
          }
        });
      }
      return certificatesWithData;
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('auth/getData', [
        'certificates',
        'customCertificates',
      ]);
      this.authCertificates = await this.$store.dispatch(
        'auth/getUserData',
        'certificates'
      );
      this.authCustomCertificates = await this.$store.dispatch(
        'auth/getUserData',
        'customCertificates'
      );

      const loggedUserCertificates = this.authCertificates;
      const resultCerts = await this.$store.dispatch(
        'certificateGenerator/getCertificatesData'
      );

      // loading title of user certificates
      this.certificatesWithTitle = [];
      let certificate;
      loggedUserCertificates.forEach(item => {
        try {
          certificate = resultCerts.find(it => it.courseID == item.courseID);
          certificate.viewTitle = this.getCertTitle(certificate.title);
          this.certificatesWithTitle.push(certificate);
        } catch (e) {
          console.log(e);
        }
      });

      await this.$store.dispatch('course/list', { clean: true });
      const customCertificatesResult = await getAllCertificates();

      this.list = this.$store.state.course.list;
      this.customCertificates = customCertificatesResult.body;
    },
    getCertTitle(title) {
      if (title[this.$i18n.locale]) {
        return title[this.$i18n.locale];
      } else if (title['es']) {
        return title['es'];
      } else if (title['pt']) {
        return title['pt'];
      } else {
        return title['en'];
      }
    },
    async downloadCertificate(courseID) {
      const result = await this.$store.dispatch('user/generateCertificate', {
        courseID: courseID,
      });

      const course = this.list.find(item => item._id == courseID);
      const name = courseUtils.certificateFilename(course, this.$i18n.locale);
      if (result.status) {
        fileUtils.download({
          name,
          type: 'application/pdf',
          data: result.body,
          isBase64: true,
        });
      }

      this.$gtm.trackEvent({
        category: 'certificates',
        event: 'click',
        action: 'certificates-click',
        label: `certificates-download-${courseID}`,
      });
    },
    async downloadCustomCertificate(certificateID) {
      const result = await downloadCustomCertificate(certificateID);

      const customCertificate = this.customCertificates.find(
        item => item._id == certificateID
      );
      // const name = courseUtils.certificateFilename(course, this.$i18n.locale);
      const name = 'certificado.pdf';
      if (result.status) {
        fileUtils.download({
          name,
          type: 'application/pdf',
          data: result.body,
          isBase64: true,
        });
      }

      this.$gtm.trackEvent({
        category: 'certificates',
        event: 'click',
        action: 'custom-certificates-click',
        label: `custom-certificates-download-${certificateID}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cert_row {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  padding: 0 15px;
  border-bottom: 1px solid #c0c5c9;
  position: relative;
  border-right: 5px solid #c0c5c9;
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    background-color: #c0c5c9;
    color: #fdc131;

    i {
      color: #fdc131 !important;
    }
  }
}
.lms-title {
  padding-left: 14px;
}
</style>
