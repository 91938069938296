<template>
  <div class="courseList">
    <SlickList
      v-model="list"
      axis="xy"
      class="kanban-list"
      :useDragHandle="true"
      @sort-start="startOrder"
      @sort-end="saveOrder"
    >
      <SlickItem
        v-for="(item, index) in list"
        :key="index"
        :index="index"
        class="kanban-list-item"
      >
        <f-course-list-item :course="item" :is-admin="allowEdit" tab="course" />
      </SlickItem>
    </SlickList>

    <!-- Remover comentarios para voltar paginacao -->
    <!-- <v-pagination
      v-model="listControl.page"
      :length="listControl.total"
      :total-visible="7"
      @next="refresh"
      @previous="refresh"
      @input="refresh"
    /> -->
  </div>
</template>

<script>
import { SlickList, SlickItem } from 'vue-slicksort';
import CourseListItem from './CourseListItem.vue';
import { UserType } from '../../helpers/user_utils';

export default {
  components: {
    SlickItem,
    SlickList,
    'f-course-list-item': CourseListItem,
  },
  data: () => ({
    course: {},
  }),
  computed: {
    allowEdit() {
      return (
        this.$store.state.auth.user.type == UserType.Admin ||
        this.$store.state.auth.user.type == UserType.TSM
      );
    },
    axis() {
      const width = window.width;
      return width > 768 ? 'x' : 'y';
    },
    list: {
      get() {
        return this.$store.state.course.list;
      },
      set(value) {
        this.$store.state.course.list = value;
      },
    },
    listControl() {
      return this.$store.state.system.listControl;
    },
    order: {
      get() {
        return this.$store.state.system.listControl.order;
      },
      set(value) {
        this.$store.state.system.listControl.order = value;
      },
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('auth/getData', ['progress', 'examAttempts']);
      await this.$store.dispatch('course/list');
    },
    async refresh() {
      await this.$store.dispatch('course/list');
    },
    startOrder() {
      this.listControl.order.willBeSaved = true;
      clearTimeout(this.order.timeoutCtl);
    },
    saveOrder() {
      this.order.saved = false;
      this.order.timeoutCtl = setTimeout(
        async function() {
          await this.$store.dispatch('course/ordersave');

          this.$store.commit('snackbar/show', {
            content: this.$t('alerts.courses.successSavedOrder'),
            type: 'success',
          });

          this.order.saved = true;

          setTimeout(
            function() {
              this.listControl.order.willBeSaved = false;
              setTimeout(
                function() {
                  this.order.saved = false;
                }.bind(this),
                800
              );
            }.bind(this),
            2000
          );
        }.bind(this),
        2000
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.courseList {
  margin: -14px -14px 0 -14px;
  width: calc(100% + 28px);
  position: relative;
}
.card-plus {
  height: 100%;
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  &__link {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    i {
      color: rgba(0, 0, 0, 0.25);
      font-size: 180px;
    }
  }
}
.waiting-save {
  background-color: #f44336;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.8s;

  &.active {
    padding: 10px 20px;
    max-height: 50px;
  }

  &.success {
    background-color: green !important;
    color: white;
  }
}
</style>
