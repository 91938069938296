<template>
  <div class="pageTabs">
    <v-tabs v-model="innerTab" @click="changeTab" fixed-tabs class="desktopTabs">
      <v-tab v-for="(item, index) in items">
        <div class="pageTabs-item">
          <div class="pageTabs-item-title">
            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
            {{ item.title }}
          </div>
          <div class="pageTabs-item-append" v-if="item.append">
            {{ item.append }}
          </div>
        </div>
      </v-tab>
    </v-tabs>
    <div class="mobilePageTabs">
      <ul>
        <li
          v-for="(item, index) in items"
          @click="innerTab = index"
          :class="{ active: index == innerTab }"
          class="mobilePageTabs--li"
        >
          <div class="mobilePageTabs--item">
            <div class="mobilePageTabs--title">
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <div class="mobilePageTabs--title--text">
                {{ item.title }}
              </div>
            </div>
            <div class="mobilePageTabs--append" v-if="item.append">
              {{ item.append }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'items'],
  data: () => ({
    innerTab: 0,
  }),
  watch: {
    innerTab(value) {
      this.$emit('input', value);
      this.$emit('tab-click', value);
    },
    value(value) {
      this.innerTab = value;
    },
  },
  created() {
    this.innerTab = this.value;
  },
  methods: {
    changeTab(index) {
      this.innerTab = index;
    },
  },
};
</script>
