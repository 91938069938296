<template>
  <v-rating
    v-bind:readonly="rating.isReadonly"
    background-color="grey lighten-2"
    color="primary"
    empty-icon="mdi-star-outline"
    full-icon="mdi-star"
    hover
    length="5"
    :size="rating.size"
    :value="rating.value"
    @input="setRating($event)"
  />
</template>

<script>
export default {
  props: {
    rating: {
      size: Number,
      isReadonly: Boolean,
      value: Number,
      parentId: String,
    },
  },
  methods: {
    async setRating(value) {
      await this.$store.dispatch('rating/setRating', {
        value,
        parentId: this.rating.parentId,
      });
    },
  },
};
</script>
