import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VTextField,{staticClass:"mx-4",attrs:{"label":_vm.$t('labels.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" "),_c(VBtn,{attrs:{"icon":"","color":"primary","to":{ name: 'report-student-view', params: { id: item._id } }}},[_c(VIcon,[_vm._v("mdi-chart-box-outline")])],1)]}},{key:`item.progress`,fn:function({ item }){return [_c('div',{staticClass:"user-progress"},[_c(VProgressCircular,{attrs:{"rotate":-90,"size":25,"width":4,"value":item.progress,"color":"primary"}}),_c('div',{staticClass:"user-progress__percent caption"},[_vm._v(_vm._s(item.progress)+"%")])],1)]}},{key:`item.done`,fn:function({ item }){return [(item.done)?_c(VIcon,[_vm._v("mdi-check")]):_vm._e()]}},{key:`item.done_date`,fn:function({ item }){return [(item.done)?_c('div',[_vm._v(" "+_vm._s(item.done_date ? _vm.$df.datetime(item.done_date) : '')+" ")]):_vm._e()]}}],null,true)}),_c('f-loader-full',{attrs:{"active":_vm.switchingUser}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }