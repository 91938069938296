<template>
  <div>
    <lms-card>
      <lms-card-table-filters>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('labels.search')"
              outlined
              hide-details
              v-model="filters.search"
            />
          </v-col>
          <v-col cols="12" sm="4" md="4" v-allowed="[userTypes.ADMIN]">
            <MasterSelect
              keep-height
              placeholder
              no-counter
              optional
              icon="mdi-map-marker-outline"
              v-model="filters.master_ids"
            />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select
              prepend-inner-icon="mdi-checkbox-marked-outline"
              placeholder="Status"
              elevation="0"
              outlined
              :items="statusOptions"
              clearable
              v-model="filters.status"
            />
          </v-col>
        </v-row>
      </lms-card-table-filters>

      <v-data-table
        :disable-sort="true"
        :footer-props="defaultFooterProps"
        :headers="headers"
        :items="innerList"
        :loading="isLoading"
        :options.sync="options"
        :page="page"
        :search="search"
        :server-items-length="this.$store.state.report.studentItemsCount"
        class="elevation-0"
        hide-default-header
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        item-key="_id"
        @item-expanded="getStudentData"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="row-bold-title">
            <v-icon>mdi-account-outline</v-icon>{{ item.name }}
          </div>
        </template>

        <template v-slot:[`item.course_counter_bar`]="{ item }">
          <div class="report-progress-content">
            <list-progress-bar :value="item.courses.percentage" />
          </div>
        </template>

        <template v-slot:[`item.courses_count`]="{ item }">
          <div class="report-progress-content">
            <div class="chip-done">{{ item.courses.done }} done</div>
            <div class="chip-doing">{{ item.courses.doing }} doing</div>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <row-courses-details :studentID="item._id" />
          </td>
        </template>
      </v-data-table>
    </lms-card>

    <f-loader-full :active="switchingUser" />
  </div>
</template>

<script>
import ListProgressBar from '../shared/ListProgressBar.vue';
import LmsDataTableSearch from '../shared/DataTableSearch.vue';
import LoaderFull from '../shared/LoaderFull';
import MasterSelect from '../shared/MasterSelect.vue';
import RowCoursesDetails from './RowCoursesDetails.vue';
import userStatuses from '../../data/userStatuses';
import { UserType } from '../../helpers/user_utils';
import { defaultFooterProps } from '../../helpers/data_table';

export default {
  components: {
    'f-loader-full': LoaderFull,
    ListProgressBar,
    LmsDataTableSearch,
    MasterSelect,
    RowCoursesDetails,
  },
  data: () => ({
    defaultFooterProps,
    expanded: [],
    filters: {
      limit: defaultFooterProps['items-per-page-options'][0],
      page: 1,
      search: '',
      master_ids: [],
      status: '',
    },
    master_ids: [],
    options: {},
    page: 1,
    search: '',
    singleExpand: true,
    status: '',
    statusOptions: userStatuses,
    switchingUser: false,
  }),
  computed: {
    innerList() {
      var percentage;
      return this.$store.state.report.studentList.map(item => {
        percentage = ((item.stats.done * 100) / item.stats.total).toFixed(2);
        percentage = !isNaN(percentage) ? percentage : 0;
        return {
          _id: item.student._id,
          name: item.student.name,
          courses: {
            doing: item.stats.total - item.stats.done,
            done: item.stats.done,
            total: item.stats.total,
            percentage,
          },
        };
      });
    },
    headers() {
      return [
        {
          text: '',
          align: 'start',
          value: 'name',
          key: 'name',
        },
        {
          text: '',
          align: 'end',
          value: 'course_counter_bar',
        },
        {
          text: '',
          align: 'end',
          value: 'courses_count',
        },
        { title: '', value: 'data-table-expand' },
      ];
    },
    showMasterFilter() {
      return this.$store.getters['auth/user'].type == UserType.Admin;
    },
    isAdmin() {
      const { user } = this.$store.state;
      return !user.isMaster;
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('report/studentList', this.filters);
    },
    get() {
      var percentage;
      let list = this.$store.state.report.studentList.map(item => {
        percentage = ((item.stats.done * 100) / item.stats.total).toFixed(2);
        percentage = !isNaN(percentage) ? percentage : 0;
        return {
          _id: item.student._id,
          name: item.student.name,
          courses: {
            doing: item.stats.total - item.stats.done,
            done: item.stats.done,
            total: item.stats.total,
            percentage,
          },
        };
      });
      this.$store.commit('system/loading', false);
      return list;
    },
    async switchAccount(user) {
      this.switchingUser = true;
      const result = await this.$store.dispatch('user/loginAs', user._id);
      if (result) {
        this.$router.push('/');
      }
    },
    async getStudentData(data) {
      this.$store.dispatch('report/studentData', data.item._id);
    },
  },
  watch: {
    'filters.master_ids': function() {
      this.filters.page = 1;
      this.loadData();
    },
    'filters.status': function() {
      this.filters.page = 1;
      this.loadData();
    },
    'filters.search': {
      handler: function() {
        clearTimeout(this.intervalSearch);
        const loadData = this.loadData;
        this.intervalSearch = setTimeout(() => {
          loadData();
        }, 500);
      },
    },
    options: {
      handler: function(values, old) {
        if (values.page !== old.page) {
          this.filters.page = values.page;
          this.loadData();
        }
        if (values.itemsPerPage !== old.itemsPerPage) {
          this.filters.limit = values.itemsPerPage;
          this.loadData();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.v-data-table__expanded {
  .report-progress-content {
    transition: opacity 0.5s;
    opacity: 0;
  }
}
</style>
