<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="15"
      class="elevation-0"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('labels.search')"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
        <v-btn
          icon
          color="primary"
          :to="{ name: 'report-student-view', params: { id: item._id } }"
        >
          <v-icon>mdi-chart-box-outline</v-icon>
        </v-btn>
        <!-- <v-tooltip top>
          <template v-if="isAdmin" v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              @click.prevent="switchAccount(item)"
            >
              <v-icon>mdi-account-switch-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.loginAs') }}</span>
        </v-tooltip> -->
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <div class="user-progress">
          <v-progress-circular
            :rotate="-90"
            :size="25"
            :width="4"
            :value="item.progress"
            color="primary"
          >
          </v-progress-circular>
          <div class="user-progress__percent caption">{{ item.progress }}%</div>
        </div>
      </template>
      <template v-slot:[`item.done`]="{ item }">
        <v-icon v-if="item.done">mdi-check</v-icon>
      </template>
      <template v-slot:[`item.done_date`]="{ item }">
        <div v-if="item.done">
          {{ item.done_date ? $df.datetime(item.done_date) : '' }}
        </div>
      </template>
    </v-data-table>
    <f-loader-full :active="switchingUser"></f-loader-full>
  </div>
</template>

<script>
import LoaderFull from '../shared/LoaderFull';

export default {
  components: {
    'f-loader-full': LoaderFull,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      search: '',
      switchingUser: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tt.capitalize(this.$t('reports.course.name')),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.course.progress')),
          align: 'start',
          value: 'progress',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.course.done')),
          align: 'start',
          value: 'done',
        },
        {
          text: this.$tt.capitalize(this.$t('reports.course.doneDate')),
          align: 'start',
          value: 'done_date',
        },
      ];
    },
    isAdmin() {
      const { user } = this.$store.state;
      return !user.isMaster;
    },
  },
  methods: {
    async switchAccount(user) {
      this.switchingUser = true;
      const result = await this.$store.dispatch('user/loginAs', user._id);
      if (result) {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-progress {
  display: flex;
  align-items: center;
  &__percent {
    margin-left: 7px;
    font-weight: 700;
  }
}
</style>
