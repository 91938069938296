var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courses-header-tabs"},[_c('a',{class:[_vm.isActiveRoute('course-list') ? 'active' : '', 'a-course-list'],on:{"click":function($event){return _vm.goTo('course-list')}}},[_vm._v(" "+_vm._s(_vm.$t("labels.allCourses"))+" ")]),_c('a',{class:[
      _vm.isActiveRoute('course-list-my-courses') ? 'active' : '',
      'a-course-list-my-courses'
    ],on:{"click":function($event){return _vm.goTo('course-list-my-courses')}}},[_vm._v(" "+_vm._s(_vm.$t("labels.myProgress"))+" ")]),_c('a',{class:[
      _vm.isActiveRoute('course-list-my-certificates') ? 'active' : '',
      'a-course-list-my-certificates'
    ],on:{"click":function($event){return _vm.goTo('course-list-my-certificates')}}},[_vm._v(" "+_vm._s(_vm.$t("labels.myCertificates"))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }