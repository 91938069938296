<template>
  <div
    class="waiting-save"
    :class="{
      active: orderControl.willBeSaved,
      success: orderControl.saved,
    }"
  >
    {{
      orderControl.saved
        ? $t('alerts.courses.successSavedOrder')
        : $t('alerts.courses.llSaveOrder')
    }}
  </div>
</template>

<script lang="js">
export default {
  computed: {
    orderControl() {
      return this.$store.state.system.listControl.order
    }
  },
}
</script>

<style lang="scss" scoped>
.waiting-save {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: #f44336;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.8s;

  &.active {
    padding: 10px 20px;
    max-height: 50px;
  }

  &.success {
    background-color: green !important;
    color: white;
  }
}
</style>
