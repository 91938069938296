<template>
  <f-page-header :title="title" :prevLinks="prevLinks" />
</template>

<script>
export default {
  props: {
    tab: Number
  },
  computed: {
    prevLinks() {
      var links = [
        {
          title: this.$t('labels.reports'),
          route: null,
        },
      ];

      return links;
    },
    title() {
      var title;

      switch (this.tab) {
        case 0:
          title = 'labels.courses';
          break;
        case 1:
          title = 'labels.students';
          break;
        default:
          title = 'labels.reports';
      }

      return this.$t(title);
    },
    tabsLinks() {
      return [
        {
          label: this.$t('labels.courses'),
          route: {
            name: 'report-list-courses',
          },
        },
        {
          label: this.$t('labels.students'),
          route: {
            name: 'report-list-students',
          },
        },
      ];
    },
  },
};
</script>
