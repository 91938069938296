import store from "../store";
import apiUtils from "../helpers/api_utils";

const ROUTE = 'custom_certificates'
const TOKEN = store.getters["auth/userToken"];

export const getAll = async () => {
  return await apiUtils.get(`${ROUTE}/all`, {}, TOKEN);
};

export const download = async (certificateID) => {
  return await apiUtils.post(`${ROUTE}/download`, {certificateID}, TOKEN);
};
