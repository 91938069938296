<template>
  <div>
    <div class="page">
      <f-page-header :title="title" :prevLinks="[]">
        <template #actions>
          <lms-page-header-action-btn
            icon="mdi-plus"
            v-allowed="[userTypes.ADMIN]"
            @click="create"
          >
            {{ $tt.capitalize($t('labels.create')) }}
          </lms-page-header-action-btn>
        </template>
      </f-page-header>
      <f-list-warning />

      <lms-default-loader v-if="isLoading" />

      <div v-if="!isLoading">
        <f-container topBottom>
          <page-tabs v-model="pageTab" :items="items" />

          <v-tabs-items v-model="pageTab">
            <v-tab-item :key="tabsRef.COURSES.tab">
              <f-list :ref="tabsRef.COURSES.ref" />
            </v-tab-item>
            <v-tab-item :key="tabsRef.MY_COURSES.tab">
              <!-- <f-list :ref="tabsRef.MY_COURSES.ref" /> -->
              <list-my-courses :ref="tabsRef.MY_COURSES.ref" />
            </v-tab-item>
            <v-tab-item :key="tabsRef.CERTIFICATES.tab">
              <list-my-certificates :ref="tabsRef.CERTIFICATES.ref" />
            </v-tab-item>
          </v-tabs-items>
        </f-container>
      </div>
    </div>
  </div>
</template>

<script>
import { UserType } from '../../helpers/user_utils';
import CourseHeaderTabs from '../../components/Courses/HeaderTabs.vue';
import FListWarning from '../../components/Courses/view/ListWarning.vue';
import PageTabs from '../../components/shared/PageTabs.vue';
import FList from '../../components/Courses/List.vue';
import ListMyCourses from '../../components/Courses/ListMyCourses.vue';
import ListMyCertificates from '../../components/Courses/ListMyCertificates.vue';

export default {
  components: {
    CourseHeaderTabs,
    FList,
    FListWarning,
    PageTabs,
    ListMyCourses,
    ListMyCertificates,
  },
  data: () => ({
    pageTab: 0,
    tabsRef: {
      COURSES: {
        ref: 'courses',
        tab: 0,
      },
      MY_COURSES: {
        ref: 'my_courses',
        tab: 1,
      },
      CERTIFICATES: {
        ref: 'certificates',
        tab: 2,
      },
    },
  }),
  computed: {
    title() {
      return this.$t('labels.courses');
    },
    tabsLinks() {
      return [
        {
          label: this.$t('labels.allCourses'),
          route: { name: 'course-list' },
        },
        {
          label: this.$t('labels.myProgress'),
          route: { name: 'course-list-my-courses' },
        },
        {
          label: this.$t('labels.myCertificates'),
          route: { name: 'course-list-my-certificates' },
        },
      ];
    },
    order() {
      return this.$store.state.system.listControl.order;
    },
    allowEdit() {
      return (
        this.$store.state.auth.user.type == UserType.Admin ||
        this.$store.state.auth.user.type == UserType.TSM
      );
    },
    listTabsViewsCounters() {
      return this.$store.getters['course/listTabsViewsCounters'];
    },
    items() {
      // solucao temporaria
      const allCourses = this.$store.state.course.list;
      const authUser = this.$store.getters['auth/user'];

      let allCoursesLen = 0;
      try {
        allCoursesLen = allCourses.length;
      } catch (e) {}

      let myCoursesLen = 0;
      try {
        let myCoursesIDS = [];

        const userProgress = authUser.progress;

        if (userProgress && userProgress.length) {
          userProgress.forEach(item => {
            myCoursesIDS.push(item.courseID);
          });
        }

        myCoursesLen = allCourses.filter(item =>
          myCoursesIDS.includes(item._id)
        ).length;
      } catch (e) {}

      let certificatesLen = 0;
      try {
        certificatesLen =
          authUser.certificates.length + authUser.customCertificates.length;
      } catch (e) {}

      return [
        {
          icon: 'mdi-school-outline',
          title: 'Courses',
          gtm: 'tab-courses',
          // append: allCoursesLen,
        },
        {
          icon: 'mdi-account-outline',
          title: 'My Progress',
          gtm: 'tab-progress',
          // append: myCoursesLen,
        },
        {
          icon: 'mdi-file-certificate-outline',
          title: 'My Certificates',
          gtm: 'tab-certificates',
          // append: certificatesLen,
        },
      ];
    },
  },
  watch: {
    pageTab() {
      this.loadTabData();
      this.$store.state.system.listControl.page = 1;

      this.$gtm.trackEvent({
        category: 'course',
        event: 'click',
        action: 'course-tab-click',
        label: 'course-tab-click',
        value: `${this.items[this.pageTab].gtm}`,
      });
    },
  },
  async created() {
    this.changeTab();
    this.loadTabData();
    this.$store.commit('system/loading', false);
    await this.$store.dispatch('course/getListTabsViewsCounters');
  },
  methods: {
    changeTab() {
      try {
        if (this.$route.query.tab == 'certificates') {
          this.pageTab = 2;
        }
      } catch (e) {
        //
      }
    },
    loadTabData() {
      let currentTabRef = '';
      switch (this.pageTab) {
        case this.tabsRef.COURSES.tab:
          currentTabRef = this.tabsRef.COURSES.ref;
          break;
        case this.tabsRef.MY_COURSES.tab:
          currentTabRef = this.tabsRef.MY_COURSES.ref;
          break;
        case this.tabsRef.CERTIFICATES.tab:
          currentTabRef = this.tabsRef.CERTIFICATES.ref;
      }

      setTimeout(() => {
        this.$refs[currentTabRef].loadData();
      }, 100);
    },
    create() {
      this.$router.push({
        name: 'course-create',
      });

      this.$gtm.trackEvent({
        category: 'course-admin-actions',
        event: 'click',
        action: 'course-click',
        label: 'btn-new-course',
      });
    },
  },
  beforeDestroy() {
    this.$store.commit('course/clearList');
    this.$store.commit('system/resetListControls');
  },
};
</script>
