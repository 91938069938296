<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <v-row v-if="isLoading">
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="table"> </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="!isLoading">
        <v-col cols="12" md="4" lg="3">
          <dashboard-counter
            color="#1976D2"
            icon="mdi-format-list-bulleted"
            :count="stats.total"
          >
            {{ $t('reports.course.studentsProgress') }}
          </dashboard-counter>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <dashboard-counter
            color="#388E3C"
            icon="mdi-check"
            :count="stats.done"
          >
            {{ $t('reports.course.doneStudents') }}
          </dashboard-counter>
        </v-col>
        <v-col cols="12">
          <report-course-view :list="list" />
        </v-col>
      </v-row>
    </f-container>
  </div>
</template>

<script>
import ReportCourseView from '../../components/Reports/ReportCourseView.vue';
import DashboardCounter from '../../components/DashboardCounter.vue';
import courseUtils from '../../helpers/course_utils';

export default {
  data() {
    return {
      belongsToTeam: Boolean,
    };
  },
  components: {
    ReportCourseView,
    DashboardCounter,
  },
  props: {
    id: String,
  },
  mounted() {
    this.$store.dispatch('report/courseView', this.$route.params.id);
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.reports'),
          route: {
            name: 'report-list-courses',
          },
        },
      ];
    },
    title() {
      const { report } = this.$store.state;
      if (!report.courseView.course) {
        return '';
      }
      return courseUtils.courseTitle(
        report.courseView.course,
        this.$i18n.locale
      )
    },
    list() {
      const { report } = this.$store.state;
      if (!report.courseView.students) {
        return [];
      }
      return report.courseView.students.map(student => {
        return {
          _id: student._id,
          name: student.name,
          progress: student.progress,
          done: student.done,
          done_date: student.done_date,
        };
      });
    },
    stats() {
      const { report } = this.$store.state;
      if (!report.courseView.stats) {
        return {
          total: 0,
          done: 0,
        };
      }
      return {
        total: report.courseView.stats.total,
        done: report.courseView.stats.done,
      };
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
