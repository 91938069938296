import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VProgressLinear,{staticClass:"report-progress",class:{
      split: !_vm.hasSecondValue && !_vm.linear,
      zero: _vm.value == 0,
      linear: _vm.linear,
      finished: _vm.value == 100,
    },style:(`width: ${+_vm.width}px;`),attrs:{"buffer-value":_vm.secondValue,"value":_vm.value,"stream":_vm.hasSecondValue}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }