<template>
  <v-dialog v-model="active" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{ $t('labels.loading') }}
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
