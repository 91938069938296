<template>
  <div class="courses-header-tabs">
    <a
      @click="goTo('course-list')"
      :class="[isActiveRoute('course-list') ? 'active' : '', 'a-course-list']"
    >
      {{ $t("labels.allCourses") }}
    </a>
    <a
      @click="goTo('course-list-my-courses')"
      :class="[
        isActiveRoute('course-list-my-courses') ? 'active' : '',
        'a-course-list-my-courses'
      ]"
    >
      {{ $t("labels.myProgress") }}
    </a>
    <a
      @click="goTo('course-list-my-certificates')"
      :class="[
        isActiveRoute('course-list-my-certificates') ? 'active' : '',
        'a-course-list-my-certificates'
      ]"
    >
      {{ $t("labels.myCertificates") }}
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    isActiveRoute(routeName) {
      return this.$route.name == routeName;
    },
    goTo(routeName) {
      this.$router.push({
        name: routeName
      });
    }
  }
};
</script>
