<template>
  <div style="padding: 10px; margin: -14px -14px 0 -14px">
    <v-row v-if="true">
      <div
        v-for="(item, index) in courses"
        :key="index"
        :index="index"
        class="kanban-list-item clear-items"
      >
        <f-course-list-item :course="item" tab="progress" />
      </div>
    </v-row>
  </div>
</template>

<script>
import CourseListItem from '../../components/Courses/CourseListItem.vue';
import { UserType } from '../../helpers/user_utils';
import CourseHeaderTabs from '../../components/Courses/HeaderTabs.vue';

export default {
  components: {
    'f-course-list-item': CourseListItem,
    CourseHeaderTabs,
  },
  data: () => ({
    userProgress: [],
  }),
  computed: {
    title() {
      return this.$t('labels.myProgress');
    },
    prevLinks() {
      var links = [
        {
          title: this.$t('labels.courses'),
          route: {
            name: 'course-list',
          },
        },
      ];

      return links;
    },
    tabsLinks() {
      return [
        {
          label: this.$t('labels.allCourses'),
          route: { name: 'course-list' },
        },
        {
          label: this.$t('labels.myProgress'),
          route: { name: 'course-list-my-courses' },
        },
        {
          label: this.$t('labels.myCertificates'),
          route: { name: 'course-list-my-certificates' },
        },
      ];
    },
    allowEdit() {
      return (
        this.$store.state.auth.user.type == UserType.Admin ||
        this.$store.state.auth.user.type == UserType.TSM
      );
    },
    courses() {
      const result = this.$store.state.course.list;

      let myCoursesIDS = [];

      if (this.userProgress && this.userProgress.length) {
        this.userProgress.forEach(item => {
          myCoursesIDS.push(item.courseID);
        });
      }

      return result.filter(item => myCoursesIDS.includes(item._id));
    },
  },
  async created() {
    // this.get();
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('auth/getData', ['progress']);
      this.userProgress = await this.$store.dispatch(
        'auth/getUserData',
        'progress'
      );
      await this.$store.dispatch('course/list');
    },
    async get() {
      await this.$store.dispatch('auth/getData', ['progress']);
      this.userProgress = await this.$store.dispatch(
        'auth/getUserData',
        'progress'
      );

      await this.$store.dispatch('course/list');
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-items {
  .course-list-item__course-handler {
    display: none;
  }
}

.kanban-column {
  padding: 10px 20px 0px 10px;
}
.kanban-list {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .column-container {
    flex-direction: column;
    align-items: stretch;
  }
  .kanban-column {
    width: auto;
  }
}
.kanban-list-item {
  margin: 20px 10px 10px 15px;
  width: 489px;
}
</style>
