<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" :tabs="tabsLinks">
      <template #actions>
        <lms-page-header-action-btn
          icon="mdi-plus"
          @click="create"
          v-allowed="[userTypes.ADMIN]"
        >
          create
        </lms-page-header-action-btn>
      </template>
    </f-page-header>

    <f-container topBottom>
      <div v-if="certificatesWithTitle.length">
        Certificados de cursos
        <div
          v-for="(item, index) in certificatesWithTitle"
          :key="index"
          class="cert_row"
          @click="downloadCertificate(item.courseID)"
        >
          <div>
            {{ item.viewTitle }}
          </div>
          <div>
            <v-icon>mdi-download</v-icon>
          </div>
        </div>
        <br />
      </div>
      <div v-if="customCertificatesList.length">
        Outros certificados
        <div
          v-for="item in customCertificatesList"
          :key="item.id"
          class="cert_row"
          @click="downloadCustomCertificate(item.certificateID)"
        >
          <div>
            {{ item.title }}
          </div>
          <div>
            <v-icon>mdi-download</v-icon>
          </div>
        </div>
      </div>
    </f-container>
  </div>
</template>

<script lang="js">
import courseUtils from "../../helpers/course_utils";
import CourseHeaderTabs from "../../components/Courses/HeaderTabs.vue";
import fileUtils from "../../helpers/file_utils";

import {
  getAll as getAllCertificates,
  download as downloadCustomCertificate
} from "../../services/customCertificates";

export default {
  components: {
    CourseHeaderTabs
  },
  data: () => ({
    list: [],
    customCertificates: [],
    certificatesWithTitle: [],
    flags: {
      loaded: false
    },
    filters: {
      main: null
    },
    userProgress: {},
    course: {},
    order: {
      timeoutCtl: null,
      willBeSave: false,
      saved: false
    },
    authCustomCertificates: [],
    authCertificates: []
  }),
  computed: {
    title() {
      return this.$t("labels.myCertificates");
    },
    prevLinks() {
      var links = [
        {
          title: this.$t("labels.courses"),
          route: {
            name: "course-list"
          }
        }
      ];

      return links;
    },
    tabsLinks() {
      return [
        {
          label: this.$t("labels.allCourses"),
          route: { name: "course-list"}
        },
        {
          label: this.$t("labels.myProgress"),
          route: { name: "course-list-my-courses"}
        },
        {
          label: this.$t("labels.myCertificates"),
          route: { name: "course-list-my-certificates"}
        }
      ];
    },
    certificates() {
      let course = {};
      let certificatesWithData = [];
      if (
        this.$store.state.user.authUser.certificates &&
        this.$store.state.user.authUser.certificates.length &&
        this.list &&
        this.list.length
      ) {
        const certificates = this.$store.state.user.authUser.certificates;
        certificates.forEach(cert => {
          if (cert) {
            try {
              course = this.list.find(item => item._id == cert.courseID);
              certificatesWithData.push({
                title: this.getCertTitle(cert), // fix this
                date: cert.date,
                id: cert._id,
                courseID: cert.courseID
              });
            } catch (e) {}
          }
        });
      }
      return certificatesWithData;
    },
    customCertificatesList() {
      let certificate = {};
      let certificatesWithData = [];
      if (
        this.authCustomCertificates &&
        this.authCustomCertificates.length &&
        this.customCertificates &&
        this.customCertificates.length
      ) {
        const certificates = this.authCustomCertificates;
        certificates.forEach(cert => {
          if (cert) {
            certificate = this.customCertificates.find(
              item => item._id == cert.certificateID
            );
            try {
              certificatesWithData.push({
                title: certificate.data.title[this.$i18n.locale],
                date: cert.data.date,
                id: cert._id,
                certificateID: cert.certificateID
              });
            } catch (e) {}
          }
        });
      }
      return certificatesWithData;
    }
  },
  created(){
    this.get();
  },
  methods: {
    getCertTitle(title) {
      if( title[this.$i18n.locale] ){
        return title[this.$i18n.locale]
      } else if( title['es'] ) {
        return title['es']
      } else if( title['pt'] ) {
        return title['pt']
      } else {
        return title['en']
      }
    },
    async downloadCertificate(courseID) {
      const result = await this.$store.dispatch("user/generateCertificate", {
        courseID: courseID
      });
      // const course = this.certificatesWithTitle.find(item => item.courseID == courseID);
      const course = this.list.find(item => item._id == courseID);
      const name = courseUtils.certificateFilename(course, this.$i18n.locale);
      if (result) {
        fileUtils.download({
          name,
          type: "application/pdf",
          data: result,
          isBase64: true
        });
      }
    },
    async downloadCustomCertificate(certificateID) {
      const result = await downloadCustomCertificate(certificateID);

      const customCertificate = this.customCertificates.find(
        item => item._id == certificateID
      );
      // const name = courseUtils.certificateFilename(course, this.$i18n.locale);
      const name = "certificado.pdf";
      if (result.status) {
        fileUtils.download({
          name,
          type: "application/pdf",
          data: result.body,
          isBase64: true
        });
      }
    },
    async get() {
      this.$store.commit("system/loading");
      await this.$store.dispatch("auth/getData", ["certificates","customCertificates"]);
      this.authCertificates = await this.$store.dispatch(
        "auth/getUserData",
        "certificates"
      );
      this.authCustomCertificates = await this.$store.dispatch(
        "auth/getUserData",
        "customCertificates"
      );


      const loggedUserCertificates = this.authCertificates
      const resultCerts = await this.$store.dispatch("certificateGenerator/getCertificatesData")

      // loading title of user certificates
      let title, certificate;
      loggedUserCertificates.forEach(item=>{
        try {
          certificate = resultCerts.find(it=>it.courseID==item.courseID);
          certificate.viewTitle = this.getCertTitle(certificate.title);
          this.certificatesWithTitle.push(certificate);
        } catch(e){
          console.log(e)
        }
      });

      await this.$store.dispatch("course/list",{clean:true});
      const customCertificatesResult = await getAllCertificates();
      this.$store.commit("system/loading", false);

      this.list = this.$store.state.course.list;
      this.customCertificates = customCertificatesResult.body;
      this.flags.loaded = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.cert_row {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #0c2033;
  padding: 0 15px;
  border-bottom: 1px solid #2a3c4d;
  position: relative;
  border-right: 5px solid #2a3c4d;
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    background-color: #22201f;
    color: #fdc131;

    i {
      color: #fdc131 !important;
    }
  }
}
</style>
