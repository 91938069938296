<template>
  <div>
    <lms-card>
      <lms-card-table-filters>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('labels.search')"
              outlined
              hide-details
              v-model="filters.search"
              @focus="trackSearch"
            />
          </v-col>
          <v-col cols="12" sm="4" md="4" v-allowed="[userTypes.ADMIN]">
            <MasterSelect
              keep-height
              placeholder
              no-counter
              optional
              icon="mdi-map-marker-outline"
              v-model="filters.master_ids"
              @input="trackMasterSelect"
            />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select
              prepend-inner-icon="mdi-checkbox-marked-outline"
              placeholder="Status"
              elevation="0"
              outlined
              :items="statusOptions"
              item-text="label"
              item-value="value"
              clearable
              v-model="filters.status"
              @change="trackStatusSelect"
            />
          </v-col>
        </v-row>
      </lms-card-table-filters>

      <v-data-table
        :disable-sort="true"
        :footer-props="defaultFooterProps"
        :headers="headers"
        :items="filteredList"
        :loading="isLoading"
        :options.sync="options"
        :page="page"
        :search="search"
        :server-items-length="this.$store.state.report.courseItemsCount"
        @click:row="view"
        class="elevation-0"
        hide-default-header
      >
        <template v-slot:[`item.course`]="{ item }">
          <div class="row-bold-title">
            {{ item.course }}
          </div>
        </template>

        <template v-slot:[`item.course_progress_bar`]="{ item }">
          <list-progress-bar :value="item.done" :second-value="item.doing" />
        </template>

        <template v-slot:[`item.course_progress_count`]="{ item }">
          <div class="chip-done">{{ item.done_absolute }} done</div>
          <div class="chip-doing">{{ item.doing_absolute }} doing</div>
          <v-btn
            class="export-xls"
            :color="item.doing_absolute != 0 ? 'primary' : 'disabled'"
            small
            @click.stop="() => exportCourseItem(item)"
            :disabled="isDownloading(item._id)"
            :loading="startDownload"
            @click="trackGtm"
          >
            <v-icon small>mdi-download</v-icon>
            <span>{{ $i18n.t('labels.exportXLS') }}</span>
          </v-btn>
        </template>
      </v-data-table>
    </lms-card>
  </div>
</template>

<script>
import ListProgressBar from '../shared/ListProgressBar.vue';
import LmsDataTableSearch from '../shared/DataTableSearch.vue';
import MasterSelect from '../shared/MasterSelect.vue';
import courseStatuses from '../../data/courseStatuses';
import courseUtils from '../../helpers/course_utils';
import fileUtils from '../../helpers/file_utils';
import { UserType } from '../../helpers/user_utils';
import { defaultFooterProps } from '../../helpers/data_table';

export default {
  components: {
    MasterSelect,
    LmsDataTableSearch,
    ListProgressBar,
  },
  data: () => ({
    courseDownloadingIds: [],
    defaultFooterProps,
    filters: {
      limit: defaultFooterProps['items-per-page-options'][0],
      page: 1,
      search: '',
      master_ids: [],
      status: '',
    },
    isMaster: '',
    main: '',
    master_ids: [],
    options: {},
    page: 1,
    search: '',
    startDownload: false,
    status: '',
    userMasters: [],
  }),
  computed: {
    statusOptions() {
      const statusOptions = [];
      for (let status of courseStatuses) {
        statusOptions.push({
          label: this.$t(`labels.status.${status}`),
          value: status,
        });
      }
      return statusOptions;
    },
    filteredList() {
      return this.$store.state.report.courseList.map(item => {
        const { done, doing } = this.calcProgress(item);
        return {
          _id: item.course._id,
          course: courseUtils.courseTitle(item.course, this.$i18n.locale),
          doing: doing,
          done: done,
          doing_absolute: item.stats.progress,
          done_absolute: item.stats.done,
          belongsToTeam: item.belongsToTeam,
          masters: item.course.masters.map(master => master._id),
        };
      });
    },
    headers() {
      return [
        {
          text: '',
          align: 'start',
          value: 'course',
        },
        {
          text: '',
          align: 'end',
          value: 'course_progress_bar',
        },
        {
          text: '',
          align: 'end',
          value: 'course_progress_count',
        },
      ];
    },
    showMasterFilter() {
      return this.$store.getters['auth/user'].type == UserType.Admin;
    },
  },
  created() {
    this.get();
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('report/courseList', this.filters);
    },
    get() {
      let list = this.$store.state.report.courseList.map(item => {
        const { done, doing } = this.calcProgress(item);
        return {
          _id: item.course._id,
          course: courseUtils.courseTitle(item.course, this.$i18n.locale),
          doing: doing,
          done: done,
          doing_absolute: item.stats.progress,
          done_absolute: item.stats.done,
          belongsToTeam: item.belongsToTeam,
          masters: item.course.masters.map(master => master._id),
        };
      });
      this.$store.commit('system/loading', false);
      return list;
    },
    trackSearch() {
      this.$gtm.trackEvent({
        category: 'report',
        event: 'search',
        action: 'search',
        label: 'report-course',
      });
    },
    trackStatusSelect() {
      this.$gtm.trackEvent({
        category: 'report',
        event: 'select',
        action: 'status',
        label: 'report-course',
      });
    },
    trackMasterSelect() {
      this.$gtm.trackEvent({
        category: 'report',
        event: 'select',
        action: 'master',
        label: 'report-course',
      });
    },
    trackGtm() {
      this.$gtm.trackEvent({
        category: 'report',
        event: 'click',
        action: 'export',
        label: 'report-course',
      });
    },
    calcProgress(item) {
      try {
        return {
          done: ((item.stats.done * 100) / item.stats.total).toFixed(2),
          doing: ((item.stats.progress * 100) / item.stats.total).toFixed(2),
        };
      } catch (e) {
        return false;
      }
    },
    view(item) {
      return;
      this.$router.push({
        name: 'report-course-view',
        params: {
          route: this.$route.name,
          id: item._id,
          belongsToTeam: item.belongsToTeam,
          teamId: this.hasTeam,
          hasLearningPathId: this.hasLearningPath,
        },
      });
    },
    exportCourseItem(item) {
      if (item.doing_absolute + item.done_absolute == 0) {
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.report.noDataToExport'),
          type: 'error',
        });
      } else {
        this.onDownloadCourse(item._id);
        this.startDownload = true;
      }
      this.startDownload = false;
    },
    isDownloading(id) {
      return this.courseDownloadingIds.includes(id);
    },
    async onDownloadCourse(courseId) {
      this.courseDownloadingIds.push(courseId);
      const result = await this.$store.dispatch('report/downloadCourse', {
        course_id: courseId,
        search: this.filters.search,
        master_ids: this.filters.master_ids,
      });
      fileUtils.download({
        name: 'report-course.xlsx',
        type: 'application/octet-stream',
        data: result,
      });
      this.courseDownloadingIds = this.courseDownloadingIds.filter(id => {
        return id !== courseId;
      });
    },
  },
  watch: {
    'filters.master_ids': function() {
      this.filters.page = 1;
      this.loadData();
    },
    'filters.status': function() {
      this.filters.page = 1;
      this.loadData();
    },
    'filters.search': {
      handler: function() {
        clearTimeout(this.intervalSearch);
        const loadData = this.loadData;
        this.intervalSearch = setTimeout(() => {
          loadData();
        }, 500);
      },
    },
    options: {
      handler: function(values, old) {
        if (values.page !== old.page) {
          this.filters.page = values.page;
          this.loadData();
        }
        if (values.itemsPerPage !== old.itemsPerPage) {
          this.filters.limit = values.itemsPerPage;
          this.loadData();
        }
      },
      deep: true,
    },
  },
};
</script>
