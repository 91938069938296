import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"course-list-item transition-swing",class:{ 'in-learning-path': _vm.isLearningPath },attrs:{"min-width":280,"elevation":hover ? 15 : 2,"disabled":_vm.isLocked,"link-focus-opacity":"1"}},[_c(VCardTitle,{staticClass:"course-list-item__title",style:(_vm.backgroundImage),on:{"click":_vm.view}},[(!_vm.isLearningPath)?_c('div',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}],staticClass:"course-list-item__course-handler"},[(_vm.isAdmin)?_c('span',{directives:[{name:"handle",rawName:"v-handle"}],staticClass:"handle course-handler"},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-dots-grid")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"course-list-item__title__progress"},[_c(VProgressCircular,{attrs:{"rotate":-90,"size":70,"width":4,"value":_vm.percent,"color":"primary"}},[_c('div',{staticClass:"course-list-item__title__progress__value"},[_vm._v(" "+_vm._s(_vm.percent)+"% ")])])],1),_c('div',{staticClass:"course-list-item__title__wrapper"},[(_vm.isAdmin)?_c('div',{staticClass:"course-list-item__title__languages"},_vm._l((_vm.languages),function(language,index){return _c('span',{key:index,staticClass:"course-list-item__title__languages__item"},[_vm._v(_vm._s(language))])}),0):_vm._e(),_c('div',[_c('h2',{staticClass:"course-list-item__title__text"},[_vm._v(_vm._s(_vm.title))]),_c('f-rating-system',{attrs:{"rating":{
                size: 18,
                isReadonly: true,
                value: _vm.course.average_rating_course,
              }}})],1)])]),_c(VCardText,{staticClass:"course-list-item__content grow",on:{"click":_vm.view}},[_c('div',{staticClass:"course-list-item__content__text",domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('br'),(
            _vm.course.trainingCategory.length != 0 &&
              _vm.$store.state.auth.user.type != 'student'
          )?_c('div',_vm._l((_vm.course.trainingCategory),function(category){return _c(VChip,{staticStyle:{"margin-right":"10px"},attrs:{"dark":"","small":""}},[_vm._v(" "+_vm._s(category)+" ")])}),1):_vm._e()]),_c('div',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}],staticClass:"admin-actions"},[_c(VCardActions,{staticClass:"justify-space-between"},[_c('div',[_c('course-status',{attrs:{"status":_vm.course.status},on:{"change":status => _vm.changeStatus(status)}})],1),_c('div',{staticClass:"btns"},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.edit(_vm.course._id)}}},[_c(VIcon,[_vm._v("mdi-pencil-outline")])],1),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.remove(_vm.course._id)}}},[_c(VIcon,[_vm._v("mdi-delete-outline")])],1)])])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }